import $ from "jquery";

$(function () {
  $("#tttt").text($("#tttt").data("ttl"));
  const countdown = setInterval(() => {
    let secondsLeft = $("#tttt").data("ttl");
    if (secondsLeft < 0) {
      clearInterval(countdown);
      return;
    }
    displayTimeLeft(secondsLeft);
  }, 1000);
});

function displayTimeLeft(seconds) {
  $("#tttt").text(seconds);
  $("#tttt").data("ttl", --seconds);
}
