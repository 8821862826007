import $ from "jquery";

const ALL_VALUES = ".rule_values";
const AR_OBJECTS = ".ar-objects";
const MODE_ONE = ".mode_one";
const MODE_TWO = ".mode_two";
const MODE_THREE = ".mode_three";
const MODE_FOUR = ".mode_four";
const MODE_COLUMN = "#game_mode";

const ChangeConditions = {
  1: [MODE_ONE, AR_OBJECTS],
  2: [MODE_TWO],
  3: [MODE_THREE, AR_OBJECTS],
  4: [MODE_FOUR, AR_OBJECTS],
  5: [AR_OBJECTS],
};

$(function () {
  const ModeValue = (value) => ChangeConditions[value];
  const ShowElement = (element) => $(element).css("display", "block");
  const ShowFields = (array) => array?.forEach(ShowElement);
  const DispatchModeChangeEvent = (value) => {
    setTimeout(() => {
      $(document)[0].dispatchEvent(
        new CustomEvent("customEvent1", {
          detail: { gameTypeID: value },
        })
      );
    }, 0);
  };

  ShowFields(ModeValue($(MODE_COLUMN).val()));
  DispatchModeChangeEvent($(MODE_COLUMN).val());

  $(MODE_COLUMN).on("change", ({ currentTarget: { value } }) => {
    DispatchModeChangeEvent(value);
    $(ALL_VALUES).css("display", "none");

    ShowFields(ModeValue(value));
  });
});
