import $ from 'jquery'
import "select2/dist/css/select2.css"

const API_SERVER_HOST = '#api_domain'

$(function () {

    $('#query_ar_obj').select2({
        width: "100%",
        language: 'zh-TW',
        containerCssClass: "form-control select optional",
        minimumInputLength: 1,
        placeholder: `輸入名稱或是id...`,
        ajax: {
            delay: 500,
            url: `${$(API_SERVER_HOST).data('api')}api/v1/search_ar_objs`,
            dataType: 'json',
            type: 'GET',
            data: function (params) {
                return {
                    search: params.term
                }
            },
            processResults: function (data) {
                return {
                    results: $.map(data.ar_objects, function (obj) {
                        return {
                            id: obj[0],
                            text: obj[1]
                        };
                    })
                }
            }
        }
    })
})