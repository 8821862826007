import $ from "jquery";

const ALL_VALUES = ".action_values";
const ACTION_NAME = ".action_name";
const VOUCHER_TYPE = ".voucher_type_id";
const POOL = ".pool-id";
const COIN_QUANTITY = ".coin-quantity";
const ACTION_SELECT = "#game_action";
const ACTION_OPTIONS = "#game_action > option";

const ChangeConditions = {
  2: [ACTION_NAME],
  3: [VOUCHER_TYPE],
  4: [COIN_QUANTITY],
  5: [COIN_QUANTITY],
  6: [VOUCHER_TYPE],
  7: [VOUCHER_TYPE],
  8: [POOL],
  9: [POOL],
};

const ACTION_NUMBER_LIST = {
  voucher: "3",
  coin: "4",
};

const getActionNumber = (arrayActionName) =>
  [...arrayActionName].map((name) => ACTION_NUMBER_LIST[name]);

const getAllowActions = (gameTypeID) => {
  if (gameTypeID === "3") return getActionNumber(["coin"]);
  if (gameTypeID === "4") return getActionNumber(["voucher"]);
};

const getModeValue = (value) => ChangeConditions[value];
const showElement = (element) => $(element).css("display", "block");
const showFields = (array) => array?.forEach(showElement);
const hideThenShowAllowFields = (actionId) => {
  $(ALL_VALUES).css("display", "none");

  showFields(getModeValue(actionId));
};

const handleChangeActionSelect = ({ currentTarget: { value } }) => {
  hideThenShowAllowFields(value);
};

$(function () {
  const AllActions = $(ACTION_SELECT).html();

  $(ACTION_SELECT).on("change", handleChangeActionSelect);

  $(document).on("customEvent1", ({ detail: { gameTypeID } }) => {
    // reset
    $(ACTION_SELECT).html(AllActions);

    // determine actions can select
    if (getAllowActions(gameTypeID)) {
      $(ACTION_SELECT).html(
        $(ACTION_OPTIONS).filter((_idx, option) =>
          getAllowActions(gameTypeID).includes(option.value)
        )
      );

      hideThenShowAllowFields($(ACTION_SELECT).val());
    }

    // TODO: change action to default option
    //
    //
  });

  hideThenShowAllowFields($(ACTION_SELECT).val());
});
