import $ from 'jquery'

const FCM_TOPIC = '#fcm_topic'
const DEVICE_ID_SELECTOR = "#fcm-device-id-selector"
const SEND_NOTIFICATION_TO_DEVICE_TYPE = "platform-personal"

$(function () {

    $(document).on('change', FCM_TOPIC, function (e) {
        if ($(FCM_TOPIC).val() == SEND_NOTIFICATION_TO_DEVICE_TYPE) {
            $(DEVICE_ID_SELECTOR).css("display", "block")
        } else {
            $(DEVICE_ID_SELECTOR).css("display", "none")
        }
    })
})